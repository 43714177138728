import AiIcon from '/imports/checkout/ui/assets/AiIcon';
import LikeIcon from '/imports/checkout/ui/assets/LikeIcon';
import {
  NewlyIcon1,
  NewlyIcon2,
  NewlyIcon3,
  NewlyIcon4,
  NewlyIcon5,
  NewlyIcon6,
} from '/imports/checkout/ui/assets/NewlyCheckoutIcons';
import PdfIcon from '/imports/checkout/ui/assets/PdfIcon';
import SupportIcon from '/imports/checkout/ui/assets/SupportIcon';
import { staticFile } from '/lib/helpers';

// Checkout A/B experiments
export const EXPERIMENT_1 = 'EXP 1';
export const EXPERIMENT_1A = 'EXP 1A';
export const EXPERIMENT_2 = 'EXP 2';
export const EXPERIMENT_3 = 'EXP 3';
export const EXPERIMENT_5 = 'EXP 5';

// list of PSP's billing version which should have the experiement 3, STRIPE:1, SOLID:5
export const NEW_PRICING_EXP_3_BILLING_VERSIONS = [5];

//list of country with close pricing exp
export const CLOSE_PRICING_COUNTRY = [
  'AR',
  'EC',
  'EG',
  'BR',
  'ID',
  'LT',
  'MX',
  'MA',
  'PH',
  'SA',
  'ES',
  'SE',
  'TH',
  'AE',
  'LV',
  'FR',
  'NL',
  'NO',
  'CL',
  'CO',
];

export const CLOSE_PRICING_COUNTRY_BROWSER_ONLY_NEW_BROWSER = ['CL'];

export const NEW_PRICING_FOR_BOTH = ['BR', 'ID', 'LT', 'MX', 'MA', 'PH', 'SA', 'ES', 'AE', 'LV', 'NL'];

export const NEW_PRICE_FOR_BROWSER_AND_RUN_MOBILE = ['ZA'];

export const CONTROL_BROSWER_RUN_MOBILE = ['AU'];

export const NEW_PRICE_ON_BROWSWER_CONTROL_MOBILE = ['FR'];

// Chargebee payment plan experiments
export const CB_EXPERIMENT_1 = 'EXP_1';

export const CARD_DETAILS_FIELD_STATES = {
  COMPLETE: 'complete',
  EMPTY: 'empty',
  INCOMPLETE: 'incomplete',
};

export const CARD_LOGOS = {
  visa: staticFile('img/visa.png'),
  mastercard: staticFile('img/mastercard.png'),
};

export const DISABLE_CHECKOUT_PRICING = ['TR', 'VN', 'PK', 'BR', 'PE', 'MX', 'TH', 'BD', 'IN'];

export const PAYMENT_CONTENT = [
  { icon: <LikeIcon />, content: 'payment_content_1' },
  { icon: <PdfIcon />, content: 'payment_content_2' },
  { icon: <AiIcon />, content: 'payment_content_3' },
  { icon: <SupportIcon />, content: 'payment_content_4' },
];

export const USER_RATING_INFORMATION = [
  {
    imageUrl: staticFile(`img/hero4.jpg`),
    userName: 'RAJENDRA',
  },
  {
    imageUrl: staticFile(`img/hero5.jpg`),
    userName: 'JULIE',
  },
  {
    imageUrl: staticFile(`img/hero6.jpg`),
    userName: 'ASHLEY',
  },
];

export const NEW_CHECKOUT_TESTIMONIAL = [
  {
    src: staticFile('img/checkout/avatar-1.jpg'),
    text: 'testimonial1',
    author: 'new_checkout_testimonial_female_name',
    job: 'sales_manager',
  },
  {
    src: staticFile('img/checkout/avatar-2.jpg'),
    text: 'testimonial2',
    author: 'new_checkout_testimonial_male_name',
    job: 'accountant',
  },
];

export const NEW_CHECKOUTPAGE_PRICING_POINTS = [
  { title: 'new_checkout_pricing_point_title_1', subTitle: `new_checkout_pricing_point_subtitle_1` },
  {
    title: 'new_checkout_pricing_point_title_2',
    subTitle: `new_checkout_pricing_point_subtitle_2`,
  },
  {
    title: 'new_checkout_pricing_point_title_3',
    subTitle: `new_checkout_pricing_point_subtitle_3`,
  },
  {
    subTitle: `new_checkout_pricing_point_subtitle_4`,
  },
  {
    subTitle: `new_checkout_pricing_point_subtitle_5`,
  },
];

export const NEWLY_LEFT_SECTION_DATA = [
  {
    icon: <NewlyIcon1 />,
    title: 'newly_left_title_1',
    subTitle: 'newly_left_subtitle_1',
  },
  {
    icon: <NewlyIcon2 />,
    title: 'newly_left_title_2',
    subTitle: 'newly_left_subtitle_2',
  },
  {
    icon: <NewlyIcon3 />,
    title: 'newly_left_title_3',
    subTitle: 'newly_left_subtitle_3',
  },
  {
    icon: <NewlyIcon4 />,
    title: 'newly_left_title_4',
    subTitle: 'newly_left_subtitle_4',
  },
  {
    icon: <NewlyIcon5 />,
    title: 'newly_left_title_5',
  },
  {
    icon: <NewlyIcon6 />,
    title: 'newly_left_title_6',
    subTitle: 'newly_left_subtitle_6',
  },
];

export const ACTIVE_PAYPAL_GEO = ['US', 'CA', 'DE', 'AU', 'NZ', 'GB'];
